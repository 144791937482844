import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside ContactMap functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		contactMap: {
			display: 'flex',
			justifyContent: 'flex-end',
			transform: 'scale(1.3)',
		},
		svg: {
			objectFit: 'contain'
		}
	}))()

export default useStyles
