import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {graphql, useStaticQuery} from 'gatsby'

import {BackdropTypographyComponent} from '@components/index'

import ContactMap from './ContactMap/index'
import useStyles from './style.hook'

/**
 * Contact
 */
const Contact: React.FC = () => {
	// Get styles from template-scoped styles hook
	const classes = useStyles()

	const {
		title,
		titleBackdrop,
		body,
		phoneNumber,
		phoneNumberText,
		email,
		emailText,
		locationMapsUrl,
		locationText
	} = useStaticQuery(graphql`
		query contentfulContactPageQuery {
			contentfulContactPage {
				title
				titleBackdrop
				body {
					body
				}
				phoneNumber
				phoneNumberText
				email
				emailText
				locationMapsUrl
				locationText
			}
		}
	`).contentfulContactPage

	return (
		<Box id="contact-template" className={classes.contact}>
			<BackdropTypographyComponent
				className={classes.title}
				backdropText={titleBackdrop}
				propsToDelegate={{variant: 'h1'}}
			>
				{title}
			</BackdropTypographyComponent>

			<Typography className={classes.body} variant="body1">
				{body.body}
			</Typography>

			<Box className={classes.contactItem}>
				<img src="/images/contact/phone.svg" alt="phone" />
				<a href={`tel:${phoneNumber}`}>{phoneNumberText}</a>
			</Box>
			<Box className={classes.contactItem}>
				<img src="/images/contact/mail.svg" alt="mail" />
				<a href={`mailto:${email}`}>{emailText}</a>
			</Box>
			<Box className={classes.contactItem}>
				<img src="/images/contact/map-pin.svg" alt="location" />
				<a target="_blank" href={locationMapsUrl}>
					{locationText}
				</a>
			</Box>
			<ContactMap className={classes.map} />
		</Box>
	)
}

export default Contact
