import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside Contact functional template
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		contact: {
			position: 'relative',
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',
			overflowX: 'hidden',
			// Oh well..
			overflowY: 'hidden',

			// Mobile
			columnGap: theme.spacing(15 / 8),
			padding: theme.spacing(150 / 8, 30 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				columnGap: theme.spacing(15 / 8),
				padding: theme.spacing(250 / 8, 75 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				columnGap: theme.spacing(30 / 8),
				padding: theme.spacing(250 / 8, 150 / 8)
			}
		},
		title: {
			gridRow: '1',
			color: theme.palette.text.primary,

			// Mobile
			gridColumn: 'span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 12'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 6'
			}
		},
		body: {
			gridRow: '2',
			margin: theme.spacing(16 / 8, 0, 40 / 8),
			color: theme.palette.text.primary,
			fontSize: 24,
			letterSpacing: '-0.33px',
			lineHeight: '28px',

			// Mobile
			gridColumn: 'span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 12'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 6'
			}
		},
		contactItem: {
			marginBottom: theme.spacing(16 / 8),
			color: theme.palette.text.primary,
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '19px',

			'& a': {
				color: theme.palette.text.primary,
				textDecoration: 'none'
			},

			'& img': {
				marginRight: theme.spacing(12 / 8)
			},

			// Mobile
			gridColumn: '2 / span 11',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '2 / span 5'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '1 / span 5'
			}
		},
		map: {
			position: 'absolute',
			zIndex: -1,
			right: 0,

			// Mobile
			bottom: -150,
			width: '100%',
			height: 'unset',

			'& #map-map-bg': {
				opacity: 0.3
			},

			// Tablet
			[theme.breakpoints.up('sm')]: {
				bottom: 0,
				width: '100%',
				height: 'unset',

				'& #map-map-bg': {
					opacity: 0.3
				}
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				top: 0,
				bottom: 'unset',
				width: '50%',
				height: '100%',

				'& #map-map-bg': {
					opacity: 1
				}
			}
		}
	}))()

export default useStyles
