import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import {Box} from '@material-ui/core'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * ContactMap
 */
const ContactMap: React.FC<CommonProps> = ({id, className}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {backgroundImage} = useStaticQuery(graphql`
		query contentfulContactPageMapQuery {
			contentfulContactPage {
				backgroundImage {
					file {
						url
					}
				}
			}
		}
	`).contentfulContactPage

	return (
		<Box
			id={id}
			className={classNames(classes.contactMap, className && className)}
		>
			<img src={backgroundImage.file.url}></img>
		</Box>
	)
}

ContactMap.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default ContactMap
